import { EventEmitter, Injectable } from '@angular/core';
import { ContactType } from '@app/models/contact-type.enum';
import { Contact } from '@app/models/contact.model';
import { LightProfil } from '@app/models/light-profil.model';
import { map, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { UtilisateurService } from './utilisateur.service';

@Injectable({
    providedIn: 'root',
})
export class ContactsService {
    public contactsUpdate$: EventEmitter<void> = new EventEmitter<void>();
    public accompagnesUpdate$: EventEmitter<void> = new EventEmitter<void>();

    // Ordre d'importance croissant :
    private contactTypeHierarchy: ContactType[] = [
        ContactType.AUTRE,
        ContactType.CERCLE_DE_CONFIANCE,
        ContactType.PERSONNE_DE_CONFIANCE,
        ContactType.TUTEUR,
    ];

    private accompagnes: Contact[];

    constructor(private apiService: ApiService, private utilisateurService: UtilisateurService) {}

    public getContactById(contactId: string): Observable<Contact> {
        return this.apiService.getContactById(this.utilisateurService.getCurrentId(), contactId);
    }

    public getContacts(): Observable<Contact[]> {
        return this.apiService.getContacts(this.utilisateurService.getCurrentId());
    }

    public createContact(contact: Contact): Observable<any> {
        return this.apiService.createContact(this.utilisateurService.getCurrentId(), contact);
    }

    public updateContact(contact: Contact): Observable<any> {
        return this.apiService.updateContact(this.utilisateurService.getCurrentId(), contact);
    }

    public validateContact(contact: Contact): Observable<any> {
        return this.apiService.validateContact(this.utilisateurService.getCurrentId(), contact);
    }

    public deleteContact(contact: Contact): Observable<any> {
        return this.apiService.deleteContact(this.utilisateurService.getCurrentId(), contact);
    }

    public emitContactsUpdate(): void {
        this.contactsUpdate$.emit();
    }

    public emitAccompagnesUpdate(): void {
        this.accompagnesUpdate$.emit();
    }

    public searchUtilisateurs(query: string): Observable<LightProfil[]> {
        query = query
            .toLowerCase()
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '');

        return this.apiService.searchUtilisateur(this.utilisateurService.getCurrentId(), query, false);
    }

    public getProfilPublic(contactId: string): Observable<LightProfil> {
        return this.apiService.getProfilPublic(contactId);
    }

    public sortByContactType(a: Contact, b: Contact): number {
        if (this.contactTypeHierarchy.indexOf(a.contactType) > this.contactTypeHierarchy.indexOf(b.contactType)) {
            return -1;
        }

        if (this.contactTypeHierarchy.indexOf(a.contactType) < this.contactTypeHierarchy.indexOf(b.contactType)) {
            return 1;
        }

        return 0;
    }

    public getAccompagnes(): Observable<Contact[]> {
        return this.apiService.getAccompagnes().pipe(
            map(data => {
                this.accompagnes = data;
                console.log(this.accompagnes);
                return data;
            }),
        );
    }

    public getAccompagneContactType(id: string): ContactType {
        console.log(this.accompagnes);
        return this.accompagnes?.find(value => value.profil.id === id)?.contactType;
    }
}
