import { DatePipe } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import { Profil } from '@app/models/profil.model';
import { ObjectUtils } from '@app/utils/object-utils';
import { LightProfil } from '@models/light-profil.model';
import { NotificationDataTranslation } from '@models/notification-data-translation.model';
import { NotificationType } from '@models/notification-type.enum';
import { Notification } from '@models/notification.model';
import { RubriqueType } from '@models/rubrique-type.enum';
import { UtilisateurType } from '@models/utilisateur-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@services/api.service';
import { UtilisateurService } from '@services/utilisateur.service';
import { Observable, Subscription } from 'rxjs';
import { RubriquesService } from './rubriques.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    public readonly REFRESH_INTERVAL: number = 60000;

    public TAG: string = '[NotificationService]';

    public notificationsUpdate$: EventEmitter<Notification[]> = new EventEmitter<Notification[]>();

    public notifications: Notification[];

    private updateSubscription: Subscription;
    private updateTimeout: number | undefined;

    constructor(
        private apiService: ApiService,
        private datePipe: DatePipe,
        private translate: TranslateService,
        private utilisateurService: UtilisateurService,
        private rubriquesService: RubriquesService,
    ) {}

    static getShortNomComplet(profil: LightProfil): string {
        return `${profil.prenom.charAt(0)}. ${profil.nomUsage}`;
    }

    static getNomComplet(profil: LightProfil): string {
        if (!profil) {
            return '';
        }
        return `${profil.prenom} ${profil.nomUsage}`;
    }

    static getDeterminant(data: NotificationDataTranslation): string {
        return data.isCurrent ? 'MINE' : data.isActionByTiers ? 'THEIR' : '';
    }

    public startInterval(): void {
        // console.log(this.TAG, 'startInterval()');

        if (this.updateTimeout) {
            clearTimeout(this.updateTimeout);
        }

        this.updateTimeout = window.setTimeout(() => this.updateNotifications(), this.REFRESH_INTERVAL);
    }

    public stopInterval(): void {
        // console.log(this.TAG, 'stopInterval()');

        this.notifications = null;

        if (this.updateSubscription?.unsubscribe) {
            this.updateSubscription.unsubscribe();
        }
        this.updateSubscription = null;

        if (this.updateTimeout) {
            clearTimeout(this.updateTimeout);
        }
        this.updateTimeout = null;
    }

    public updateNotifications(): void {
        if (this.updateSubscription?.unsubscribe) {
            return;
        }

        // console.log(this.TAG, 'updateNotifications()');

        this.updateSubscription = this.getNotifications().subscribe({
            next: (value: Notification[]) => {
                const temp = value;
                if (this.notifications?.length === temp.length) {
                    for (let i = 0; i < temp.length; i++) {
                        if (temp[i].id !== this.notifications[i].id || temp[i].vu !== this.notifications[i].vu) {
                            this.notifications = temp;
                            this.notificationsUpdate$.emit(this.notifications);
                            break;
                        }
                    }
                } else {
                    this.notifications = temp;
                    this.notificationsUpdate$.emit(this.notifications);
                }

                if (this.updateSubscription && this.updateSubscription.unsubscribe) {
                    this.updateSubscription.unsubscribe();
                    this.updateSubscription = null;
                }

                this.startInterval();
            },
            error: (err: any) => {
                console.error(err);

                if (this.updateTimeout !== null) {
                    this.startInterval();
                }
            },
        });
    }

    public getNotifications(): Observable<Notification[]> {
        return this.apiService.getNotifications();
    }

    public getNotification(notificationId: string): Observable<Notification> {
        return this.apiService.getNotification(notificationId);
    }

    public readNotification(notificationId: string): Observable<Notification> {
        return this.apiService.readNotifications(notificationId);
    }

    public getNotificationTitle(type: NotificationType): string {
        return `NOTIFICATIONS.ACTIONS.${ObjectUtils.getEnumKeyByEnumValue(NotificationType, type)}.TITLE`;
    }

    public getNotificationSubtitle(notification: Notification): string {
        return `${NotificationsService.getShortNomComplet(notification.historique.utilisateurAuthentifie)} -
        ${this.datePipe.transform(notification.historique.date, 'shortDate')}`;
    }

    public getNotificationLabel(notification: Notification): string {
        console.log(notification.historique.actionType);

        const label = `NOTIFICATIONS.ACTIONS.${ObjectUtils.getEnumKeyByEnumValue(
            NotificationType,
            notification.historique.actionType,
        )}.LABEL`;

        return label;
    }

    public getNotificationDeepLink(notification: Notification): string | undefined {
        let link: string | undefined;
        switch (notification.historique.actionType) {
            case NotificationType.CONSULTER_CONTENU:
            case NotificationType.CREER_CONTENU:
            case NotificationType.MODIFIER_CONTENU:
                link = `/${this.rubriquesService.getUrlByRubriqueType(notification.historique.data.rubriqueType)}/${
                    notification.historique.data.contenuId
                }/details?deep=true`;
                break;

            default:
                break;
        }

        return link;
    }

    public getNotificationLabelData(notification: Notification): NotificationDataTranslation {
        const isCurrent = this.utilisateurService.getCurrentId() === notification.historique.utilisateur.id;
        const autreIsCurrent = this.utilisateurService.getCurrentId() === notification.historique.utilisateurAutre?.id;
        const isActionByTiers =
            !isCurrent &&
            notification.historique.utilisateurAuthentifie.utilisateurType !== UtilisateurType.BENEFICIAIRE;

        const defaultData: NotificationDataTranslation = {
            utilisateur: NotificationsService.getNomComplet(notification.historique.utilisateurAuthentifie),
            utilisateurConcerne: NotificationsService.getNomComplet(notification.historique.utilisateur),
            utilisateurAutre: NotificationsService.getNomComplet(notification.historique.utilisateurAutre),
            isCurrent,
            isActionByTiers,
            autreIsCurrent,
        };

        // console.log(defaultData);

        return this.setSpecificDataTranslation(notification, defaultData);
    }

    private setSpecificDataTranslation(
        notification: Notification,
        data: NotificationDataTranslation,
    ): NotificationDataTranslation {
        const historiqueData = notification.historique.data;
        const notificationType: NotificationType = notification.historique.actionType;
        const rubriqueType = ObjectUtils.getEnumKeyByEnumValue(RubriqueType, historiqueData?.rubriqueType);
        switch (notificationType) {
            case NotificationType.CONSULTER_CONTENU:
            case NotificationType.CREER_CONTENU:
            case NotificationType.MODIFIER_CONTENU:
            case NotificationType.SUPPRIMER_CONTENU:
            case NotificationType.EXPIRER_DOCUMENT:
                data = {
                    ...data,
                    rubrique: this.translate.instant(`NOTIFICATIONS.RUBRIQUES.${rubriqueType}`, {
                        determinant: NotificationsService.getDeterminant(data),
                    }),
                    contenu: historiqueData.contenuLabel || historiqueData.rubriqueLabel,
                };
                break;
            case NotificationType.INITIER_CONTACT:
                data = {
                    ...data,
                };
                break;
            case NotificationType.MODIFIER_PROFIL:
            case NotificationType.COMPLETE_ONBOARDING:
            case NotificationType.ABANDON_ONBOARDING:
                break;
            case NotificationType.MODIFIER_ACCES:
                data = {
                    ...data,
                    accesType: historiqueData.accesType,
                    rubrique: this.translate.instant(`GLOBAL.ENUMS.RUBRIQUES.${rubriqueType}`),
                    contenu: historiqueData.rubriqueLabel,
                };
                break;
            case NotificationType.MODIFIER_CONTACT:
                data = {
                    ...data,
                    contactType: historiqueData.contactType,
                };
                break;
            case NotificationType.VALIDER_CGU:
            case NotificationType.VALIDER_TYPE_COMPTE:
            case NotificationType.VALIDER_TUTEUR:
            case NotificationType.CHANGER_MOT_DE_PASSE:
                data = {
                    ...data,
                    step: this.translate.instant(
                        `GLOBAL.ENUMS.ON_BOARDING_STEP.${ObjectUtils.getEnumKeyByEnumValue(
                            NotificationType,
                            notificationType,
                        )}`,
                    ),
                };
                break;

            default:
                break;
        }
        return data;
    }
}
