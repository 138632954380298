import { Component, Input, OnInit } from '@angular/core';
import { PopoverType } from '@app/models/popover-type.enum';
import { UtilisateurService } from '@app/services/utilisateur.service';
import { PopoverController } from '@ionic/angular';
import { AbstractComponent } from '../abstract/abstract.component';

@Component({
    selector: 'app-popover',
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent extends AbstractComponent implements OnInit {
    @Input()
    public type: PopoverType = PopoverType.SUCCESS;

    @Input()
    public message?: string;

    @Input()
    public icon?: string;

    @Input()
    public description?: string;

    @Input()
    public buttonAction?: () => void;

    @Input()
    public isConfirmationPopover?: boolean = false;

    @Input()
    public buttonLabel?: string;

    @Input()
    public showButton: boolean = false;

    @Input()
    public cancelButtonLabel?: string;

    constructor(public popoverCtrl: PopoverController, private utilisateurService: UtilisateurService) {
        super();
    }

    ngOnInit(): void {
        this.isConsultation = this.utilisateurService.isConsultation();
    }

    public validateAction(): void {
        this.popoverCtrl.dismiss({ confirm: true });
    }
}
