import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoadingData } from '@emotic/emolib/lib/core/models/loading-data.model';
import { Platform } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';
import { Utilisateur } from '@models/utilisateur.model';
import { environment } from 'src/environments/environment';
import { RouteService } from '@services/route.service';
import { UtilisateurType } from '@models/utilisateur-type.enum';
import { RubriqueType } from './models/rubrique-type.enum';
import { NotificationsService } from './services/notifications.service';
import { PopoverType } from './models/popover-type.enum';
import { AbstractPage } from './pages/abstract/abstract.page';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent extends AbstractPage implements OnInit, AfterViewChecked {
    public appPages: {
        title: string;
        url: string;
        icon: string;
        disabled: boolean;
        type?: RubriqueType;
        rights?: UtilisateurType[];
    }[] = [
        {
            title: 'PARCOURS.TITRE',
            url: '/parcours',
            icon: 'ph-line-segments-fill',
            disabled: false,
            type: RubriqueType.PARCOURS,
            rights: [UtilisateurType.BENEFICIAIRE],
        },
        {
            title: 'CHOIX-DE-VIE.TITRE',
            url: '/choix-de-vie',
            icon: 'ph-heart-fill',
            disabled: false,
            type: RubriqueType.CHOIX_DE_VIE,
            rights: [UtilisateurType.BENEFICIAIRE],
        },
        {
            title: 'CARNET-LIAISON.TITRE',
            url: '/cahier-de-liaison',
            icon: 'ph-book-fill',
            disabled: false,
            type: RubriqueType.CARNET_LIAISON,
            rights: [UtilisateurType.BENEFICIAIRE],
        },
        {
            title: 'DOCUMENTS.TITRE',
            url: '/mes-documents',
            icon: 'ph-folder-fill',
            disabled: false,
            //disabled: this.configService.getConfig().env !== 'local',
            type: RubriqueType.DOSSIER_PARTAGE,
            rights: [UtilisateurType.BENEFICIAIRE],
        },
        {
            title: 'CONTACTS.TITRE',
            url: '/mes-contacts',
            icon: 'ph-users-four-fill',
            disabled: false,
            rights: [UtilisateurType.BENEFICIAIRE],
        },
        {
            title: 'NOTIFICATIONS.TITRE',
            url: '/mes-notifications',
            icon: 'ph-bell-ringing-fill',
            disabled: false,
        },
        {
            title: 'DROITS-ACCES.TITRE',
            url: '/droits-acces',
            icon: 'ph-key-fill',
            disabled: false,
            rights: [UtilisateurType.BENEFICIAIRE],
        },
        {
            title: 'ACCOMPAGNES.TITRE',
            url: '/personnes-accompagnees',
            icon: 'ph-users-four-fill',
            disabled: false,
            rights: [UtilisateurType.AIDANT_NON_PRO, UtilisateurType.AIDANT_PRO, UtilisateurType.PRESTATAIRE],
        },
        {
            title: 'PROFIL.TITRE',
            url: '/mon-profil',
            icon: 'ph-user-fill',
            disabled: false,
        },
    ];

    public menuButtons: {
        title: string;
        url: string;
        icon: string;
        disabled: boolean;
        type?: RubriqueType;
        rights?: UtilisateurType[];
    }[] = [];

    public version: string = environment.version;

    public isBeneficiaire: boolean;
    public isPro: boolean;
    public isConsultation: boolean;

    public isAuthenticated: boolean;

    public loadingMessage?: string;

    public hideMainHeader: boolean = true;

    public TAG: string = '[AppComponent]';

    public loadingData: LoadingData;

    public rubriquesAccess: Record<string, boolean>;

    private tmpLoadingData: LoadingData;

    private isNotTuteur: boolean;

    private hideMainHeaderUrls = [
        '/onboarding',
        '/login',
        '/mot-de-passe-oublie',
        '/reinitialisation-du-mot-de-passe',
        '/modification-du-mot-de-passe',
        '/cgu',
    ];

    private isMobile: boolean = false;

    constructor(
        private cdRef: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private platform: Platform,
        private router: Router,
        private routeService: RouteService, // Ici pour initialiser le service et récupérer toutes les routes depuis le lancement de l'app
    ) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.loadingService.show(this.TAG);

        this.platform.ready().then(() => {
            this.isMobile = this.platform.is('mobile') && (this.platform.is('android') || this.platform.is('iphone'));
        });

        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                for (const str of this.hideMainHeaderUrls) {
                    this.hideMainHeader = event.url.includes(str);

                    if (this.hideMainHeader) {
                        return;
                    }
                }
            }
        });

        this.loadingService.loadingData$.subscribe({
            next: (value?: LoadingData) => {
                this.tmpLoadingData = value;
            },
            error: (err: any) => console.error(err),
        });

        this.securityService.isAuthenticatedUpdate$.subscribe({
            next: (value: boolean) => (this.isAuthenticated = value),
            error: (err: any) => this.handleError(err),
        });

        this.utilisateurService.rubriquesAccessUpdate$.subscribe({
            next: (value: Record<string, boolean>) => this.updateRubriquesAccess(value),
            error: (err: any) => this.handleError(err, this.TAG),
        });

        this.securityService.loadPreviousSession().subscribe({
            next: (utilisateur: Utilisateur) => {
                if (utilisateur) {
                    if (location.href.includes('/onboarding?utilisateurKey')) {
                        this.securityService.logout().then(() => this.loadingService.hide(this.TAG));
                        return;
                    }

                    let page = '/home';
                    if (!utilisateur.onBoardingValidated || !utilisateur.lastCguValidated) {
                        page = '/onboarding/creer-compte';
                    }
                    this.navController.navigateRoot(page);
                }
                this.loadingService.hide(this.TAG);
            },
            error: (err: any) => this.handleError(err, this.TAG),
        });

        this.updateCurrentUtilisateurType();
    }

    // https://stackoverflow.com/questions/43513421/ngif-expression-has-changed-after-it-was-checked
    // https://medium.com/better-programming/expressionchangedafterithasbeencheckederror-in-angular-what-why-and-how-to-fix-it-c6bdc0b22787
    ngAfterViewChecked() {
        if (this.loadingData !== this.tmpLoadingData) {
            this.loadingData = this.tmpLoadingData;
            this.loadingMessage = this.loadingData?.message;
            this.cdRef.detectChanges();
        }
    }

    public logout(): void {
        this.loadingService.show(this.TAG);
        this.notificationsService.stopInterval();

        this.securityService.logout().then(() => {
            this.navController.navigateRoot('/login');
            this.loadingService.hide(this.TAG);
        });
    }

    public mesAccompagnesClick(): void {
        this.utilisateurService.resetCurrentProfil();
        this.navController.navigateRoot('/personnes-accompagnees');
    }

    public allo360ClickHandler(): void {
        // if (this.isMobile) {
        this.popoverService.show(
            PopoverType.SUCCESS,
            'ALLO360.TITRE',
            'ph-phone-call',
            'ALLO360.DESCRIPTION',
            true,
            'ALLO360.APPELER',
            this.callAllo360,
            true,
        );
        // } else {
        //     this.popoverService.show(
        //         PopoverType.SUCCESS,
        //         'ALLO360.TITRE',
        //         'ph-phone-call',
        //         'ALLO360.DESCRIPTION',
        //         true,
        //         'GLOBAL.FERMER',
        //     );
        // }
    }

    public callAllo360 = () => {
        window.open('tel:0800360360');
    };

    protected updateCurrentUtilisateurType(): void {
        super.updateCurrentUtilisateurType();

        // Nous n'affichons les contacts ou les droits d'accès que s'il s'agit d'un bénéficiaire
        // accédant directement à son compte, ou un tuteur en mode consultation :
        this.isNotTuteur = !this.utilisateurService.isTuteur();

        this.updateMenuButtons();
    }

    // /**
    //  *
    //  * @param err
    //  * @param loaderId : passer '' pour forcer le masquage du loader.
    //  */
    protected handleError(err: any, loaderId?: string): void {
        console.log(err);

        if (loaderId) {
            this.loadingService.hide(loaderId);
        }
    }

    private updateMenuButtons(): void {
        this.menuButtons = this.appPages.filter(({ rights, url }) => {
            let value = rights ? rights.includes(this.currentUtilisateurType) : true;

            if (this.isConsultation) {
                switch (url) {
                    case '/mes-contacts':
                        value = !this.isNotTuteur;
                        break;

                    case '/droits-acces':
                        value = !this.isNotTuteur;
                        break;

                    case '/mes-notifications':
                        value = false;
                        break;

                    default:
                        break;
                }
            }

            return value;
        });
    }

    private updateRubriquesAccess(value: Record<string, boolean>): void {
        this.rubriquesAccess = value;

        for (const bt of this.menuButtons) {
            if (bt.type) {
                bt.disabled = this.isConsultation && !this.rubriquesAccess[bt.type];
            }
        }
    }
}
