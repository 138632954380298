import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from '@services/auth-guard.service';
import { BeneficiaireGuardService as BeneficiaireGuard } from '@services/beneficiaire-guard.service';
import { PrestataireGuardService as PrestataireGuard } from '@services/prestataire-guard.service';

const routes: Routes = [
    {
        path: 'mot-de-passe-oublie',
        loadChildren: () =>
            import('@pages/password-forgotten/password-forgotten.module').then(m => m.PasswordForgottenPageModule),
    },
    {
        path: 'reinitialisation-du-mot-de-passe',
        loadChildren: () => import('@pages/password-reset/password-reset.module').then(m => m.PasswordResetPageModule),
    },
    {
        path: 'cgu',
        loadChildren: () => import('@pages/cgu/cgu.module').then(m => m.CguPageModule),
    },
    {
        path: 'onboarding',
        loadChildren: () => import('@pages/onboarding/onboarding.module').then(m => m.OnboardingPageModule),
    },
    {
        path: 'login',
        loadChildren: () => import('@pages/login/login.module').then(m => m.LoginPageModule),
    },
    {
        path: 'home',
        loadChildren: () => import('@pages/home/home.module').then(m => m.HomePageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'mon-profil',
        loadChildren: () => import('@pages/mon-profil/mon-profil.module').then(m => m.MonProfilPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'choix-de-vie',
        loadChildren: () => import('@pages/choix-de-vie/choix-de-vie.module').then(m => m.ChoixDeViePageModule),
        canActivate: [AuthGuard, BeneficiaireGuard],
    },
    {
        path: 'parcours',
        loadChildren: () => import('@pages/parcours/parcours.module').then(m => m.ParcoursPageModule),
        canActivate: [AuthGuard, BeneficiaireGuard],
    },
    {
        path: 'cahier-de-liaison',
        loadChildren: () =>
            import('@pages/carnet-de-liaison/carnet-de-liaison.module').then(m => m.CarnetDeLiaisonPageModule),
        canActivate: [AuthGuard, BeneficiaireGuard],
    },
    {
        path: 'mes-contacts',
        loadChildren: () => import('@pages/mes-contacts/mes-contacts.module').then(m => m.MesContactsPageModule),
        canActivate: [AuthGuard, BeneficiaireGuard],
    },
    {
        path: 'personnes-accompagnees',
        loadChildren: () =>
            import('./pages/mes-accompagnes/mes-accompagnes.module').then(m => m.MesAccompagnesPageModule),
        canActivate: [AuthGuard, PrestataireGuard],
    },
    {
        path: 'mes-documents',
        loadChildren: () => import('@pages/mes-documents/mes-documents.module').then(m => m.MesDocumentsPageModule),
        canActivate: [AuthGuard, BeneficiaireGuard],
    },
    {
        path: 'mes-notifications',
        loadChildren: () =>
            import('./pages/mes-notifications/mes-notifications.module').then(m => m.MesNotificationsPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'droits-acces',
        loadChildren: () => import('./pages/droits-acces/droits-acces.module').then(m => m.DroitsAccesPageModule),
        canActivate: [AuthGuard, BeneficiaireGuard],
    },
    {
        path: 'modification-du-mot-de-passe',
        loadChildren: () =>
            import('./pages/password-change/password-change.module').then(m => m.PasswordChangePageModule),
        canActivate: [AuthGuard],
    },
    {
        path: '**',
        redirectTo: 'home',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
