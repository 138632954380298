import { Component, Input } from '@angular/core';
import { AbstractComponent } from '@components/abstract/abstract.component';
import { AccesTotal } from '@models/acces-total.model';
import { Rubrique } from '@models/rubrique.model';
import { ContenusService } from '@services/contenus.service';

@Component({
    selector: 'app-rubrique-acces-card',
    templateUrl: './rubrique-acces-card.component.html',
    styleUrls: ['./rubrique-acces-card.component.scss'],
})
export class RubriqueAccesCardComponent extends AbstractComponent {
    @Input()
    public label: string = '';

    @Input()
    public subtitle?: string;

    @Input()
    public icon?: string;

    constructor() {
        super();
    }
}
