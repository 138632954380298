import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractComponent } from '@components/abstract/abstract.component';

@Component({
    selector: 'app-onboarding-footer',
    templateUrl: './onboarding-footer.component.html',
    styleUrls: ['./onboarding-footer.component.scss'],
})
export class OnboardingFooterComponent extends AbstractComponent {
    @Output()
    public cancel: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public validate: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    public validForm: boolean;

    @Input()
    public showCancel: boolean = true;

    @Input()
    public validateLabel: string;

    @Input()
    public cancelLabel: string;

    constructor() {
        super();
    }
}
