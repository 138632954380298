import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@services/config.service';
import { Observable } from 'rxjs';
import { SecurityService } from '@services/security.service';

@Injectable()
export class HttpAddCredentialsInterceptor implements HttpInterceptor {
    constructor(private securityService: SecurityService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const backendUrl = ConfigService.config.backendUrl;
        const remoteBackend = backendUrl && request.urlWithParams.indexOf(backendUrl) === 0;
        if (remoteBackend) {
            const jwt = this.securityService.tokenJwt;
            const headers = jwt ? request.headers.set('Authorization', jwt) : request.headers;
            request = request.clone({
                withCredentials: true,
                headers,
            });
        }

        return next.handle(request);
    }
}
