import { EventEmitter, Injectable } from '@angular/core';
import { RubriqueCode } from '@app/models/rubrique-code.enum';
import { RubriqueType } from '@app/models/rubrique-type.enum';
import { Rubrique } from '@app/models/rubrique.model';
import { AccesTotal } from '@models/acces-total.model';
import { Acces } from '@models/acces.model';
import { ApiService } from '@services/api.service';
import { UtilisateurService } from '@services/utilisateur.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RubriquesService {
    public accesUpdate$: EventEmitter<void> = new EventEmitter<void>();

    private currentRubriqueLabel: string;

    private urlsByRubriqueType: Record<string, string> = {
        [RubriqueType.CHOIX_DE_VIE]: 'choix-de-vie',
        [RubriqueType.CARNET_LIAISON]: 'cahier-de-liaison',
        [RubriqueType.DOSSIER_PARTAGE]: 'mes-documents',
        [RubriqueType.PARCOURS]: 'parcours',
    };

    constructor(private apiService: ApiService, private utilisateurService: UtilisateurService) {}

    public emitAccesUpdate(): void {
        this.accesUpdate$.emit();
    }

    public getUrlByRubriqueType(type: RubriqueType): string {
        return this.urlsByRubriqueType[type];
    }

    public setCurrentRubriqueLabel(value: string): void {
        this.currentRubriqueLabel = value;
    }

    public getCurrentRubriqueLabel(): string {
        return this.currentRubriqueLabel;
    }

    public getRubriqueById(id: string): Observable<Rubrique> {
        return this.apiService.getRubriqueById(id);
    }

    public getRubriquesByType(type: RubriqueType): Observable<Rubrique[]> {
        return this.apiService.getRubriquesByType(this.utilisateurService.getCurrentId(), type);
    }

    public getRubriqueByCode(type: RubriqueType, code: RubriqueCode): Observable<Rubrique> {
        return this.apiService.getRubriqueByCode(this.utilisateurService.getCurrentId(), type, code);
    }

    public getAllAcces(): Observable<AccesTotal[]> {
        return this.apiService.getAllAcces(this.utilisateurService.getCurrentId());
    }

    public getAccesByRubriqueId(rubriqueId: string): Observable<Acces[]> {
        return this.apiService.getAccesByRubriqueId(this.utilisateurService.getCurrentId(), rubriqueId);
    }

    public getAccesHomeByAccompagnantId(): Observable<Record<string, boolean>> {
        return this.apiService.getAccesHomeByAccompagnantId(this.utilisateurService.getCurrentId());
    }

    public getAccesByAccompagnantId(): Observable<Acces[]> {
        return this.apiService.getAccesByAccompagnantId(
            this.utilisateurService.getCurrentId(),
            this.utilisateurService.getUtilisateurId(),
        );
    }

    public getAccesRubriqueByAccompagnantId(rubriqueId: string, accompagnantId: string): Observable<Acces> {
        return this.apiService.getAccesRubriqueByAccompagnantId(
            this.utilisateurService.getCurrentId(),
            rubriqueId,
            accompagnantId,
        );
    }

    public createOrUpdateAcces(acces: Acces): Observable<Acces> {
        return this.apiService.createOrUpdateAcces(this.utilisateurService.getCurrentId(), acces);
    }
}
