import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root',
})
export class ApiCrudService {
    public static BACKEND_URL: string;
    public static readonly ROOT_URL: string = 'api/mobile/';
    public static readonly ENTITY_ACCOUNT: string = 'account';
    public static readonly ENTITY_UTILISATEUR: string = 'utilisateur';
    public static readonly ENTITY_PROFIL: string = 'profil';
    public static readonly ENTITY_CONTENUS: string = 'contenus';
    public static readonly ENTITY_CONTACTS: string = 'contacts';
    public static readonly ENTITY_BENEFICIAIRES: string = 'beneficiaires';
    public static readonly ENTITY_CGU: string = 'cgu';
    public static readonly ENTITY_PASSWORD_RESET: string = 'password/reset';
    public static readonly ENTITY_PASSWORD_FORGOT: string = 'password/forget';
    public static readonly ENTITY_PASSWORD_CHANGE: string = 'password/change';
    public static readonly ENTITY_RECHERCHE: string = 'recherche';
    public static readonly ENTITY_RUBRIQUES: string = 'rubriques';
    public static readonly ENTITY_NOTIFICATIONS: string = 'notifications';

    // HttpClient without interceptor
    private httpClientWithout: HttpClient;

    constructor(public httpClient: HttpClient, public handler: HttpBackend, public configService: ConfigService) {
        this.httpClientWithout = new HttpClient(handler);
        ApiCrudService.BACKEND_URL = this.configService.getConfig().backendUrl;
    }

    /**
     *
     * Http call without interceptor
     *
     */

    public getWithout(path: string): Observable<any> {
        const url = this.buildUrl(path);
        return this.httpClientWithout.get(url).pipe(catchError(this.handleError.bind(this)));
    }

    public postWithout(path: string, data: any, observe: any = 'body'): Observable<any> {
        const url = this.buildUrl(path);
        const headers = this.getDefaultHeaders();
        return this.httpClientWithout
            .post(url, data, { headers, observe })
            .pipe(catchError(this.handleError.bind(this)));
    }

    /**
     *
     * Http call with interceptor
     *
     */

    public get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        const url = this.buildUrl(path);
        const headers = this.getDefaultHeaders();
        return this.httpClient.get(url, { headers, params }).pipe(catchError(this.handleError.bind(this)));
    }

    public getFile(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        const url = this.buildUrl(path);
        const headers = this.getFileHeaders();
        return this.httpClient
            .get(url, { headers, params, responseType: 'blob' })
            .pipe(catchError(this.handleError.bind(this)));
    }

    public post(path: string, data: any, observe: any = 'body'): Observable<any> {
        const url = this.buildUrl(path);
        const headers = this.getDefaultHeaders();
        return this.httpClient.post(url, data, { headers, observe }).pipe(catchError(this.handleError.bind(this)));
    }

    public put(path: string, data: any): Observable<any> {
        const url = this.buildUrl(path);
        const headers = this.getDefaultHeaders();
        return this.httpClient.put(url, data, { headers }).pipe(catchError(this.handleError.bind(this)));
    }

    public delete(path: string): Observable<any> {
        const url = this.buildUrl(path);
        const headers = this.getDefaultHeaders();
        return this.httpClient.delete(url, { headers }).pipe(catchError(this.handleError.bind(this)));
    }

    /**
     *
     * Specific methods
     *
     */

    // /!\ Warning no 'Content-Type' headers for upload!
    public upload(path: string, data: any): Observable<any> {
        const url = this.buildUrl(path);
        return this.httpClient.post(url, data).pipe(catchError(this.handleError.bind(this)));
    }

    public putUpload(path: string, data: any): Observable<any> {
        const url = this.buildUrl(path);
        return this.httpClient.put(url, data).pipe(catchError(this.handleError.bind(this)));
    }

    public buildUrl(path: string): string {
        return ApiCrudService.BACKEND_URL + ApiCrudService.ROOT_URL + path;
    }

    private handleError(error: HttpErrorResponse): Observable<never> {
        if (!!error) {
            if (!!error.error && !!error.error.message) {
                return throwError(() => new Error(error.error.message));
            } else if (error.message) {
                return throwError(() => new Error(error.message));
            } else if (typeof error === 'string') {
                return throwError(() => new Error(error));
            }
        }
        return throwError(() => new Error('Une erreur est survenue.'));
    }

    private getDefaultHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    private getFileHeaders(): HttpHeaders {
        return new HttpHeaders({
            'Response-Type': 'arrayBuffer',
        });
    }
}
