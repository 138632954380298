import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractComponent } from '@components/abstract/abstract.component';

@Component({
    selector: 'app-bloc-information',
    templateUrl: './bloc-information.component.html',
    styleUrls: ['./bloc-information.component.scss'],
})
export class BlocInformationComponent extends AbstractComponent {
    @Input()
    public description: string;

    @Input()
    public isConsultation: boolean;

    @Input()
    public isClosable: boolean = false;

    @Output()
    public closeDescriptionClick: EventEmitter<void> = new EventEmitter<void>();

    constructor() {
        super();
    }

    public closeDescription(): void {
        this.closeDescriptionClick.emit();
    }

}
