import { animate, query, stagger, group, style, transition, trigger } from '@angular/animations';

export const hintAnimation = trigger('hintAnimation', [
    transition(':enter', [
        style({ opacity: 0, height: '0px', paddingTop: 0, paddingBottom: 0, margin: 0, overflow: 'hidden' }),
        animate('0.3s ease', style({ opacity: 1, height: '*', paddingTop: '*', paddingBottom: '*', margin: '*' })),
    ]),
    transition(':leave', [
        style({ overflow: 'hidden' }),
        animate('0.3s ease', style({ opacity: 0, height: '0px', paddingTop: 0, paddingBottom: 0, margin: 0 })),
    ]),
]);

export const fadeAnimation = trigger('fadeAnimation', [
    transition(':enter', [style({ opacity: 0 }), animate('0.3s ease', style({ opacity: 1 }))]),
    transition(':leave', [animate('0.3s ease', style({ opacity: 0 }))]),
]);

export const listAnimation = trigger('listAnimation', [
    transition('* => *', [
        query(
            ':enter',
            [
                style({ transform: 'scale(0.8)', opacity: 0 }),
                stagger(50, [
                    group([
                        animate(
                            '0.5s cubic-bezier(0.175, 0.885, 0.12, 1.275)',
                            style({
                                transform: 'scale(1)',
                            }),
                        ),
                        animate(
                            '0.25s ease-in',
                            style({
                                opacity: 1,
                            }),
                        ),
                    ]),
                ]),
            ],
            { optional: true, limit: 12 },
        ),
        query(
            ':leave',
            [
                stagger(25, [
                    group([
                        animate(
                            '0.2s cubic-bezier(0.6, -0.08, 0.735, 0.045)',
                            style({
                                transform: 'scale(0.8)',
                            }),
                        ),
                        animate(
                            '0.1s 100ms ease-out',
                            style({
                                opacity: 0,
                            }),
                        ),
                    ]),
                ]),
            ],
            { optional: true, limit: 12 },
        ),
    ]),
]);
