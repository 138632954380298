import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'adjectives',
})
export class AdjectivesPipe implements PipeTransform {
    constructor(private translate: TranslateService) {}

    transform(key: string, isConsultation: boolean): string {
        if (isConsultation) {
            const newKey = key + '-C';
            const translation = this.translate.instant(newKey);
            if (translation !== newKey && translation !== '') {
                return key + '-C';
            }
        }
        return key;
    }
}
