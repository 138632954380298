import { Directive, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { ContactType } from '@app/models/contact-type.enum';
import { UtilisateurType } from '@app/models/utilisateur-type.enum';
import { Utilisateur } from '@app/models/utilisateur.model';
import { AppInjector } from '@app/services/app-injector.service';
import { SecurityService } from '@app/services/security.service';
import { UtilisateurService } from '@app/services/utilisateur.service';
import { AbstractComponent } from '@components/abstract/abstract.component';
import { NavController } from '@ionic/angular';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractPage extends AbstractComponent implements OnInit, OnDestroy {
    public navController: NavController;
    public utilisateurType: UtilisateurType;
    public currentUtilisateurType: UtilisateurType;

    public isBeneficiaire: boolean;
    public isPro: boolean;
    public isConsultation: boolean;
    public isTuteur: boolean;
    public isPersonneDeConfiance: boolean;

    public isDeepLink: boolean;

    protected utilisateurService: UtilisateurService;
    protected securityService: SecurityService;

    constructor() {
        super();

        const injector = AppInjector.getInjector();

        this.securityService = injector.get(SecurityService);
        this.utilisateurService = injector.get(UtilisateurService);
        this.navController = injector.get(NavController);
    }

    ngOnInit() {
        this.subscriptions.utilisateurUpdate = this.utilisateurService.utilisateurUpdate$.subscribe({
            next: (value: Utilisateur) => {
                this.utilisateurType = value?.utilisateurType;
                this.updateCurrentUtilisateurType();
            },
            error: (err: any) => this.handleError(err, this.TAG),
        });

        this.utilisateurType = this.utilisateurService.getUtilisateurType();

        this.subscriptions.currentProfilUpdate = this.utilisateurService.currentProfilUpdate$.subscribe({
            next: () => this.updateCurrentUtilisateurType(),
            error: (err: any) => this.handleError(err, this.TAG),
        });

        this.updateCurrentUtilisateurType();
    }

    ngOnDestroy() {
        super.ngOnDestroy();

        this.loadingService.hide(this.TAG);
    }

    public setIsDeepLink(snapshot: ActivatedRouteSnapshot): void {
        this.isDeepLink = snapshot?.queryParamMap?.get('deep') === 'true';
    }

    public goBack(root: boolean = false): void {
        if (this.isDeepLink) {
            this.utilisateurService.resetCurrentProfil();
        }

        if (root) {
            this.navController.navigateBack('home');
        } else {
            this.navController.back();
        }
    }

    protected updateCurrentUtilisateurType(): void {
        this.currentUtilisateurType = this.utilisateurService.getCurrentUtilisateurType();

        this.isConsultation = this.utilisateurService.isConsultation();

        this.isBeneficiaire = this.utilisateurService.isBeneficiaire();
        this.isPro = !this.isBeneficiaire;

        this.isTuteur = this.utilisateurService.isTuteur();
        this.isPersonneDeConfiance = this.utilisateurService.isPersonneDeConfiance();
    }

    /**
     *
     * @param err
     * @param loaderId : passer '' pour forcer le masquage du loader.
     */
    protected handleError(err: any, loaderId?: string, message?: string): void {
        super.handleError(err, loaderId);

        console.log(err);

        if (err.toString().includes(`L'accès en consultation à cette ressource est refusé`)) {
            this.goBack();
            this.popoverService.errorCustom('ERROR.ACCESS-FORBIDDEN.TITRE', true, 'ERROR.ACCESS-FORBIDDEN.DESCRIPTION');
        } else {
            this.popoverService.error(message, true);
        }
    }
}
