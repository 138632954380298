import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cgu } from '@app/models/cgu.model';
import { Contact } from '@app/models/contact.model';
import { RubriqueType } from '@app/models/rubrique-type.enum';
import { Contenu } from '@app/models/contenu.model';
import { LightProfil } from '@app/models/light-profil.model';
import { Profil } from '@app/models/profil.model';
import { Utilisateur } from '@app/models/utilisateur.model';
import { AccesTotal } from '@models/acces-total.model';
import { Acces } from '@models/acces.model';
import { Notification } from '@models/notification.model';
import { ApiCrudService } from '@services/api-crud.service';
import { ConfigService } from '@services/config.service';
import { Observable } from 'rxjs';
import { Rubrique } from '@app/models/rubrique.model';
import { RubriqueCode } from '@app/models/rubrique-code.enum';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(public configService: ConfigService, public apiCrudSrv: ApiCrudService) {}

    ////// PROFIL

    public getProfil(utilisateurId: string): Observable<Profil> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_PROFIL}`;
        return this.apiCrudSrv.get(uri);
    }

    public updateProfil(utilisateurId: string, data: Profil): Observable<Profil> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_PROFIL}`;
        return this.apiCrudSrv.put(uri, data);
    }

    public deleteProfil(utilisateurId: string): Observable<any> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_PROFIL}`;
        return this.apiCrudSrv.delete(uri);
    }

    public uploadPhotoProfil(utilisateurId: string, file: File): Observable<string> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/profil/photo`;
        const data = new FormData();
        data.append('mimetype', file.type);
        data.append('filename', file.name);
        data.append('file', file);
        return this.apiCrudSrv.upload(uri, data);
    }

    public getProfilPublic(contactId: string): Observable<LightProfil> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${contactId}/${ApiCrudService.ENTITY_PROFIL}/public`;
        return this.apiCrudSrv.get(uri);
    }

    ////// UTILISATEUR

    public getUtilisateur(): Observable<Utilisateur> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}`;
        return this.apiCrudSrv.get(uri);
    }

    public validateUtilisateurType(data: any): Observable<any> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/type/validate`;
        return this.apiCrudSrv.post(uri, data);
    }

    /**
     * @param contacts  undefined : renvoie les contacts et les pas encore contacts,
     *                  false : renvoie tout ceux qui ne sont pas des contacts,
     *                  true : renvoie uniquement ceux qui sont des contacts,
     */
    public searchUtilisateur(utilisateurId: string, query: string, contacts?: boolean): Observable<LightProfil[]> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_RECHERCHE}`;
        let httpParams = new HttpParams().append('query', query);
        if (contacts !== undefined) {
            httpParams = httpParams.append('contacts', contacts);
        }
        return this.apiCrudSrv.get(uri, httpParams);
    }

    public validateTuteur(utilisateurId: string, data: Contact): Observable<any> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_CONTACTS}/${data.profil.id}/validate`;
        return this.apiCrudSrv.post(uri, data);
    }

    public isActivated(activationKey: string): Observable<{ isActivated: boolean }> {
        const uri = `${ApiCrudService.ENTITY_ACCOUNT}`;
        const httpParams = new HttpParams().append('resetKey', activationKey);
        return this.apiCrudSrv.get(uri, httpParams);
    }

    ////// CONTENU

    public getContenus(utilisateurId: string): Observable<Contenu[]> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_CONTENUS}`;
        return this.apiCrudSrv.get(uri);
    }

    public getContenuById(utilisateurId: string, contenuId: string): Observable<Contenu> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_CONTENUS}/${contenuId}`;
        return this.apiCrudSrv.get(uri);
    }

    public getContenusByType(utilisateurId: string, type: RubriqueType): Observable<Contenu[]> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_CONTENUS}?rubriqueType=${type}`;
        // const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_CONTENUS}/${type}`;
        return this.apiCrudSrv.get(uri);
    }

    public getContenusByParentCode(utilisateurId: string, code: RubriqueCode | string): Observable<Contenu[]> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_CONTENUS}?rubriqueParentCode=${code}`;
        return this.apiCrudSrv.get(uri);
    }

    public getContenusByCode(utilisateurId: string, code: RubriqueCode | string): Observable<Contenu[]> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_CONTENUS}?rubriqueCode=${code}`;
        return this.apiCrudSrv.get(uri);
    }

    // public getContenuDetail(utilisateurId: string, contenuId: string, type: RubriqueType): Observable<Contenu> {
    //     const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_CONTENUS}/${type}/${contenuId}`;
    //     return this.apiCrudSrv.get(uri);
    // }

    public createOrUpdateContenu(utilisateurId: string, contenu: Contenu): Observable<Contenu> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_CONTENUS}/${
            contenu.id || ''
        }`;
        return this.apiCrudSrv.put(uri, contenu);
    }

    public createOrUpdateContenuWithDocument(
        utilisateurId: string,
        contenu: Contenu,
        document: File,
    ): Observable<Contenu> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_CONTENUS}/${
            contenu.id || ''
        }`;

        const formData: FormData = new FormData();
        if (document) {
            formData.append('mimetype', document.type);
            formData.append('filename', document.name);
            formData.append('file', document);
        }

        formData.append(
            'contenu',
            new Blob([JSON.stringify(contenu)], {
                type: 'application/json',
            }),
        );
        if (!!contenu.id) {
            return this.apiCrudSrv.putUpload(uri, formData);
        } else {
            return this.apiCrudSrv.upload(uri, formData);
        }
    }

    public deleteContenu(utilisateurId: string, contenuId: string): Observable<any> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_CONTENUS}/${contenuId}`;
        return this.apiCrudSrv.delete(uri);
    }

    public downloadFile(url: string): Observable<any> {
        const uri = url;
        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.append('responseType', 'blob');
        httpParams = httpParams.append('observe', 'response');
        return this.apiCrudSrv.getFile(url); // , httpParams);
    }

    /// RUBRIQUES

    public getRubriqueById(rubriqueId: string): Observable<Rubrique> {
        const uri = `${ApiCrudService.ENTITY_RUBRIQUES}/${rubriqueId}`;
        return this.apiCrudSrv.get(uri);
    }

    public getRubriquesByType(utilisateurId: string, type: RubriqueType): Observable<Rubrique[]> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_RUBRIQUES}/${type}`;
        return this.apiCrudSrv.get(uri);
    }

    public getRubriqueByCode(utilisateurId: string, type: RubriqueType, code: RubriqueCode): Observable<Rubrique> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_RUBRIQUES}/${type}/${code}`;
        return this.apiCrudSrv.get(uri);
    }

    public getAllAcces(utilisateurId: string): Observable<AccesTotal[]> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_RUBRIQUES}/acces/total`;
        return this.apiCrudSrv.get(uri);
    }

    public getAccesByRubriqueId(utilisateurId: string, rubriqueId: string): Observable<Acces[]> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_RUBRIQUES}/${rubriqueId}/acces`;
        return this.apiCrudSrv.get(uri);
    }

    public getAccesHomeByAccompagnantId(beneficiaireId: string): Observable<Record<string, boolean>> {
        return this.apiCrudSrv.get(`acces/home`, new HttpParams().append('beneficiaireId', beneficiaireId));
    }

    public getAccesByAccompagnantId(utilisateurId: string, accompagnantId: string): Observable<Acces[]> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/accompagnants/${accompagnantId}/acces`;
        return this.apiCrudSrv.get(uri);
    }

    public getAccesRubriqueByAccompagnantId(
        utilisateurId: string,
        rubriqueId: string,
        accompagnantId: string,
    ): Observable<Acces> {
        // eslint-disable-next-line max-len
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_RUBRIQUES}/${rubriqueId}/accompagnants/${accompagnantId}/acces`;
        return this.apiCrudSrv.get(uri);
    }

    public createOrUpdateAcces(utilisateurId: string, acces: Acces): Observable<any> {
        // eslint-disable-next-line max-len
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_RUBRIQUES}/${acces.rubrique.id}/accompagnants/${acces.accompagnant.id}/acces`;
        return this.apiCrudSrv.put(uri, acces);
    }

    ////// ONBOARDING

    /// CGU

    public getCgu(): Observable<Cgu> {
        const uri = `${ApiCrudService.ENTITY_CGU}`;
        return this.apiCrudSrv.getWithout(uri);
    }

    public acceptCgu(cgu: Cgu): Observable<any> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${ApiCrudService.ENTITY_CGU}`;
        return this.apiCrudSrv.post(uri, cgu);
    }

    ////// PASSWORD

    public passwordReset(key: string, newPassword: string): Observable<Profil> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${ApiCrudService.ENTITY_PASSWORD_RESET}`;
        return this.apiCrudSrv.postWithout(
            uri,
            {
                key,
                newPassword,
            },
            'response',
        );
    }

    public passwordForgot(mail: string): Observable<any> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${ApiCrudService.ENTITY_PASSWORD_FORGOT}`;
        // TODO: Vérifier valeur à envoyer :
        return this.apiCrudSrv.postWithout(uri, { mail }, 'response');
    }

    public passwordChange(oldPassword: string, newPassword: string): Observable<any> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${ApiCrudService.ENTITY_PASSWORD_CHANGE}`;
        return this.apiCrudSrv.post(uri, {
            oldPassword,
            newPassword,
        });
    }

    ////// CONTACTS

    public getContactById(utilisateurId: string, contactId: string): Observable<Contact> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_CONTACTS}/${contactId}`;
        return this.apiCrudSrv.get(uri);
    }

    public getContacts(utilisateurId: string): Observable<Contact[]> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_CONTACTS}`;
        return this.apiCrudSrv.get(uri);
    }

    public createContact(utilisateurId: string, contact: Contact): Observable<Contact> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_CONTACTS}`;
        return this.apiCrudSrv.post(uri, contact);
    }

    public updateContact(utilisateurId: string, contact: Contact): Observable<Contact> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_CONTACTS}/${
            contact.profil?.id || ''
        }`;
        return this.apiCrudSrv.put(uri, contact);
    }

    public validateContact(utilisateurId: string, contact: Contact): Observable<any> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_CONTACTS}/${
            contact.profil?.id || ''
        }/validate`;
        return this.apiCrudSrv.post(uri, contact);
    }

    public deleteContact(utilisateurId: string, contact: Contact): Observable<any> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${utilisateurId}/${ApiCrudService.ENTITY_CONTACTS}/${
            contact.profil?.id || ''
        }`;
        return this.apiCrudSrv.delete(uri);
    }

    ////// ACCOMPAGNES

    public getAccompagnes(): Observable<Contact[]> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${ApiCrudService.ENTITY_BENEFICIAIRES}`;
        return this.apiCrudSrv.get(uri);
    }

    ////// NOTIFICATIONS
    public getNotifications(): Observable<Notification[]> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${ApiCrudService.ENTITY_NOTIFICATIONS}`;
        return this.apiCrudSrv.get(uri);
    }

    public getNotification(notificationId: string): Observable<Notification> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${ApiCrudService.ENTITY_NOTIFICATIONS}/${notificationId}`;
        return this.apiCrudSrv.get(uri);
    }

    public readNotifications(notificationId: string): Observable<Notification> {
        const uri = `${ApiCrudService.ENTITY_UTILISATEUR}/${ApiCrudService.ENTITY_NOTIFICATIONS}/${notificationId}/vu`;
        return this.apiCrudSrv.post(uri, {});
    }
}
