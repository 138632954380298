import { Component, Input, OnInit } from '@angular/core';
import { ApiCrudService } from '@app/services/api-crud.service';
import { UtilisateurService } from '@app/services/utilisateur.service';
import { AbstractComponent } from '../abstract/abstract.component';

@Component({
    selector: 'app-profil-photo',
    templateUrl: './profil-photo.component.html',
    styleUrls: ['./profil-photo.component.scss'],
})
export class ProfilPhotoComponent extends AbstractComponent implements OnInit {
    @Input()
    public big: boolean = false;

    @Input()
    public contactPhotoUrl?: string;

    @Input()
    public utilisateur: boolean = false;

    public utilisateurPhotoUrl?: string;

    constructor(private utilisateurService: UtilisateurService) {
        super();
    }

    ngOnInit() {
        // Si contactPhotoUrl = null, récupérons le current user, si contactPhotoUrl = undefined, affichons le picto.
        if (this.utilisateur) {
            this.subscriptions.utilisateurUpdate = this.utilisateurService.utilisateurUpdate$.subscribe({
                next: () => this.updateCurrentPhoto(),
                error: (err: any) => console.error(err),
            });

            this.subscriptions.currentProfilUpdate = this.utilisateurService.currentProfilUpdate$.subscribe({
                next: () => this.updateCurrentPhoto(),
                error: (err: any) => console.error(err),
            });

            this.updateCurrentPhoto();
        }
    }

    public get photoUrl(): string {
        // https://stackoverflow.com/questions/24381480/remove-duplicate-forward-slashes-from-the-url/24381515
        if (this.contactPhotoUrl) {
            return (ApiCrudService.BACKEND_URL + this.contactPhotoUrl).replace(/([^:]\/)\/+/g, '$1');
        }
        if (this.utilisateurPhotoUrl) {
            return (ApiCrudService.BACKEND_URL + this.utilisateurPhotoUrl).replace(/([^:]\/)\/+/g, '$1');
        }
        return null;
    }

    private updateCurrentPhoto(): void {
        this.utilisateurPhotoUrl = this.utilisateurService.getCurrentPhotoUrl();
    }
}
