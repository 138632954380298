import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractComponent } from '@components/abstract/abstract.component';
import { Contact } from '@app/models/contact.model';
import { listAnimation } from '@app/animations/animations';
import { ContactsService } from '@app/services/contacts.service';
import { PopoverConfirmData } from '@app/models/popover-data.model';
import { UtilisateurService } from '@app/services/utilisateur.service';
import { UtilisateurType } from '@app/models/utilisateur-type.enum';

@Component({
    selector: 'app-contact-list',
    templateUrl: './contact-list.component.html',
    styleUrls: ['./contact-list.component.scss'],
    animations: [listAnimation],
})
export class ContactListComponent extends AbstractComponent {
    @Input()
    public title: string;

    @Input()
    public icon: string;

    @Input()
    public contacts: Contact[] = [];

    @Input()
    public accompagnes: boolean = false;

    @Input()
    public highlight: boolean = false;

    @Input()
    public demande: boolean = false;

    @Input()
    public displayActionButton: boolean = true;

    @Input()
    public activeSelectCard?: boolean = false;

    @Output()
    public selectContact?: EventEmitter<string> = new EventEmitter<string>();

    public TAG: string = '[ContactListComponent]';

    constructor(private contactsService: ContactsService, private utilisateurService: UtilisateurService) {
        super();
    }

    /**
     * Accepter ou refuser une demande de contact.
     */
    public contactCardClick(event: { contact: Contact; validate: boolean }): void {
        this.openPopoverConfirm(event);
    }

    public emitContactSelected(contact: Contact): void {
        if (this.activeSelectCard) {
            this.selectContact.emit(contact.profil.id);
        }
    }

    private openPopoverConfirm(event: { contact: Contact; validate: boolean }): void {
        const popoverData: PopoverConfirmData = {
            message: event.validate ? 'CONFIRMATION.CONTACT.VALIDER.TITRE' : 'CONFIRMATION.CONTACT.REFUSER.TITRE',
            description: event.validate
                ? 'CONFIRMATION.CONTACT.VALIDER.DESCRIPTION'
                : 'CONFIRMATION.CONTACT.REFUSER.DESCRIPTION',
            buttonAction: event.validate
                ? () => this.validateContact(event.contact)
                : () => this.deleteContact(event.contact),
            buttonLabel: event.validate ? 'GLOBAL.ACCEPTER' : 'GLOBAL.REFUSER',
            // cancelButtonLabel: event.validate ? 'GLOBAL.REFUSER' : 'GLOBAL.ANNULER',
        };

        this.popoverService.confirm(popoverData);
    }

    private validateContact(contact: Contact): void {
        this.loadingService.show(this.TAG);
        this.subscriptions.validateContact = this.contactsService.validateContact(contact).subscribe({
            next: () => {
                this.loadingService.hide(this.TAG);
                if (
                    this.isConsultation ||
                    this.utilisateurService.getCurrentUtilisateurType() === UtilisateurType.BENEFICIAIRE
                ) {
                    this.contactsService.emitContactsUpdate();
                } else {
                    this.contactsService.emitAccompagnesUpdate();
                }
            },
            error: (err: any) => this.handleError(err, this.TAG),
        });
    }

    private deleteContact(contact: Contact): void {
        this.loadingService.show(this.TAG);
        this.subscriptions.deleteContact = this.contactsService.deleteContact(contact).subscribe({
            next: () => {
                this.loadingService.hide(this.TAG);
                this.contactsService.emitContactsUpdate();
            },
            error: (err: any) => this.handleError(err, this.TAG),
        });
    }
}
