// src/app/auth/auth-guard.service.ts
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SecurityService } from './security.service';
@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(public securityService: SecurityService, public router: Router) {}

    canActivate(): boolean {
        if (!this.securityService.isAuthenticated) {
            this.router.navigate(['login']);
            return false;
        }
        return true;
    }
}
