/* eslint-disable @typescript-eslint/indent */
import { Injectable } from '@angular/core';
import { PopoverComponent } from '@app/components/popover/popover.component';
import { PopoverConfirmData } from '@app/models/popover-data.model';
import { PopoverType } from '@app/models/popover-type.enum';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PopoverService {
    constructor(private popoverController: PopoverController, private translateService: TranslateService) {}

    public async show(
        type: PopoverType,
        message?: string,
        icon?: string,
        description?: string,
        showButton?: boolean,
        buttonLabel?: string,
        buttonAction?: () => void,
        isConfirmationPopover?: boolean,
        cancelButtonLabel?: string,
    ): Promise<void> {
        const popover = await this.popoverController.create({
            component: PopoverComponent,
            componentProps: {
                type,
                message,
                icon,
                description,
                showButton,
                buttonLabel,
                buttonAction,
                isConfirmationPopover,
                cancelButtonLabel,
            },
            animated: true,
            backdropDismiss: !buttonAction,
        });

        popover.onclick = () => {
            popover.dismiss();
        };

        await popover.present();

        if (buttonAction) {
            await popover.onWillDismiss().then(({ data }) => {
                if (!isConfirmationPopover || data?.confirm) {
                    buttonAction();
                }
            });
        }
    }

    public async success(
        message?: string,
        showButton?: boolean,
        buttonAction?: () => void,
        description?: string,
    ): Promise<void> {
        await this.show(
            PopoverType.SUCCESS,
            message ? message : 'SUCCESS.UPDATE-FORM',
            'ph-check-circle',
            description ? description : undefined,
            showButton,
            'GLOBAL.CONTINUER',
            buttonAction,
        );
    }

    public async warn(message: string, description?: string): Promise<void> {
        await this.show(
            PopoverType.WARNING,
            message,
            'ph-warning-circle',
            description ? description : undefined,
            true,
            'GLOBAL.CONTINUER',
        );
    }

    public async error(message?: string, closebutton?: boolean): Promise<void> {
        const messageContent = !!message
            ? this.translateService.instant('GLOBAL.ERROR_MSG', { error: message })
            : 'GLOBAL.ERROR';
        await this.show(
            PopoverType.ERROR,
            messageContent,
            'ph-warning-circle',
            undefined,
            closebutton,
            'GLOBAL.FERMER',
        );
    }

    public async errorCustom(message: string, closebutton?: boolean, description?: string): Promise<void> {
        await this.show(
            PopoverType.ERROR,
            message,
            'ph-warning-circle',
            description ? description : 'ERROR.RETRY',
            closebutton,
            'GLOBAL.FERMER',
        );
    }

    public async confirm(data: PopoverConfirmData): Promise<void> {
        await this.show(
            PopoverType.CONFIRM,
            data.message,
            'ph-question',
            data.description,
            true,
            data.buttonLabel,
            data.buttonAction,
            true,
            data.cancelButtonLabel,
        );
    }
}
