import { Component, Input } from '@angular/core';
import { listAnimation } from '@animations/animations';
import { UtilisateurType } from '@app/models/utilisateur-type.enum';
import { AbstractComponent } from '@components/abstract/abstract.component';
import { NavController } from '@ionic/angular';
import { NotificationDataTranslation } from '@models/notification-data-translation.model';
import { Notification } from '@models/notification.model';
import { NotificationsService } from '@services/notifications.service';

@Component({
    selector: 'app-notification-card',
    templateUrl: './notification-card.component.html',
    styleUrls: ['./notification-card.component.scss'],
    animations: [listAnimation],
})
export class NotificationCardComponent extends AbstractComponent {
    @Input()
    public notification: Notification;

    @Input()
    public isBeneficiaire: boolean = true;

    public UtilisateurType = UtilisateurType;

    constructor(private notificationsService: NotificationsService, private navController: NavController) {
        super();
    }

    public getNotificationTitle(): string {
        return this.notificationsService.getNotificationTitle(this.notification.historique.actionType);
    }

    public getNotificationSubtitle(): string {
        return this.notificationsService.getNotificationSubtitle(this.notification);
    }

    public getNotificationLabel(): string {
        return this.notificationsService.getNotificationLabel(this.notification);
    }

    public getNotificationLabelData(): NotificationDataTranslation {
        return this.notificationsService.getNotificationLabelData(this.notification);
    }

    public readNotification(): void {
        this.navController.navigateForward(`/mes-notifications/${this.notification.id}`);
    }
}
