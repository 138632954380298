import { Component, Input, OnInit } from '@angular/core';
import { hintAnimation } from '@app/animations/animations';
import { AbstractComponent } from '@components/abstract/abstract.component';
import { StorageService } from '@services/storage.service';

@Component({
    selector: 'app-page-title',
    templateUrl: './page-title.component.html',
    styleUrls: ['./page-title.component.scss'],
    animations: [hintAnimation],
})
export class PageTitleComponent extends AbstractComponent implements OnInit {
    @Input()
    public title: string;

    @Input()
    public icon?: string;

    @Input()
    public danger?: boolean;

    @Input()
    public date?: string;

    @Input()
    public isConsultation: boolean = false;

    @Input()
    public subtitle?: string;

    @Input()
    public subtitleParam?: { [key: string]: string | boolean | number };

    @Input()
    public helpDescription?: string;

    public isDescriptionClosed: boolean;

    private localKey: string;

    constructor(private storage: StorageService) {
        super();
    }

    async ngOnInit() {
        this.localKey = `${this.helpDescription}-closed`;
        this.isDescriptionClosed = await this.storage.get(this.localKey);
    }

    public openDescription(): void {
        if (this.isDescriptionClosed) {
            this.storage.set(this.localKey, false);
            this.isDescriptionClosed = false;
        } else {
            this.closeDescription();
        }
    }

    public closeDescription(): void {
        this.storage.set(this.localKey, true);
        this.isDescriptionClosed = true;
    }
}
