import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Profil } from '@app/models/profil.model';
import { ContactsService } from '@app/services/contacts.service';
import { RouteService } from '@app/services/route.service';
import { UtilisateurService } from '@app/services/utilisateur.service';
import { AbstractComponent } from '@components/abstract/abstract.component';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-card-item',
    templateUrl: './card-item.component.html',
    styleUrls: ['./card-item.component.scss'],
})
export class CardItemComponent extends AbstractComponent {
    @Output()
    public actionButtonClick: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public cancelButtonClick: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public title: string = '';

    @Input()
    public date: string = '';

    @Input()
    public photoUrl?: string;

    @Input()
    public subtitle: string = '';

    @Input()
    public suptitle?: string;

    @Input()
    public icon?: string;

    @Input()
    public danger?: boolean;

    @Input()
    public displayActionButton: boolean = true;

    @Input()
    public displayValidateButtons: boolean = false;

    @Input()
    public actionLabel: string = 'GLOBAL.VOIR';

    @Input()
    public actionIcon: string = 'ph-eye-bold';

    @Input()
    public profilId?: string;

    constructor(
        private utilisateurService: UtilisateurService,
        private contactsService: ContactsService,
        private routeService: RouteService,
        private translate: TranslateService,
        private navController: NavController,
    ) {
        super();
    }

    public actionClick(): void {
        this.actionButtonClick.emit();
    }

    public cancelClick(): void {
        this.cancelButtonClick.emit();
    }

    public photoClick(): void {
        if (this.profilId) {
            this.subscriptions.setCurrentProfilById = this.utilisateurService
                .setCurrentProfilById(this.profilId, this.contactsService.getAccompagneContactType(this.profilId))
                .subscribe({
                    next: (value: Profil) => {
                        if (!this.routeService.getCurrentUrl().includes('home')) {
                            this.navController.navigateBack('/home');
                        }
                        this.loadingService.hide(this.TAG);
                        this.popoverService.success(
                            this.translate.instant('SUCCESS.ACCOMPAGNE.LOAD', {
                                name: this.utilisateurService.getCurrentNomComplet(),
                            }),
                            true,
                        );
                    },
                    error: (err: any) => this.handleError(err, this.TAG),
                });
        }
    }
}
