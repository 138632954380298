import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { filter, pairwise /*Subject*/ } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RouteService {
    // public update$: Subject<{ current: string; previous: string }> = new Subject<{
    //     current: string;
    //     previous: string;
    // }>();

    private previousUrl: string;
    private currentUrl: string;

    /**
     * https://stackoverflow.com/questions/62847593/how-to-dynamically-go-to-previous-page-ionic-4/62851249#62851249
     */
    constructor(private router: Router) {
        this.currentUrl = this.router.url;
        this.previousUrl = null;

        this.router.events
            .pipe(
                filter((event: any) => event instanceof RoutesRecognized),
                pairwise(),
            )
            .subscribe((events: RoutesRecognized[]) => {
                this.previousUrl = events[0].urlAfterRedirects;
                this.currentUrl = events[1].urlAfterRedirects;

                // this.update$.next({ current: this.currentUrl, previous: this.previousUrl });
            });
    }

    public getPreviousUrl() {
        return this.previousUrl;
    }

    public getCurrentUrl() {
        return this.currentUrl;
    }
}
