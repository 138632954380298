/* eslint-disable @typescript-eslint/indent */
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ToastService {
    constructor(private toastController: ToastController, private translateService: TranslateService) {}

    public async show(cssClass?: string, message?: string, duration?: number, closebutton?: boolean): Promise<void> {
        const toast = await this.toastController.create({
            message: message || this.translateService.instant('GLOBAL.SUCCESS'),
            duration: closebutton ? 0 : duration || 3000,
            cssClass,
            color: 'primary',
            animated: true,
            position: 'bottom',
            buttons: closebutton
                ? [
                      {
                          side: 'end',
                          icon: 'close',
                          // text: 'Fermer',
                          // handler: () => {
                          //     console.log('Ok clicked');
                          // }
                      },
                  ]
                : undefined,
        });
        toast.present();
        toast.onclick = () => {
            toast.dismiss();
        };
    }

    public async success(message?: string, duration?: number, closebutton?: boolean): Promise<void> {
        this.show(
            'app-toast app-toast-success',
            message ? this.translateService.instant(message) : this.translateService.instant('SUCCESS.UPDATE-FORM'),
            duration,
        );
    }

    public async warn(message?: string, duration?: number): Promise<void> {
        this.show(
            'app-toast app-toast-warn',
            message ? this.translateService.instant(message) : this.translateService.instant('ERROR.UPDATE-FORM'),
            duration,
        );
    }

    public async error(message?: string, duration?: number, closebutton?: boolean): Promise<void> {
        const messageContent = !!message
            ? this.translateService.instant('GLOBAL.ERROR_MSG', { error: message })
            : this.translateService.instant('GLOBAL.ERROR');
        // const mess = `
        //     <div class="">
        //         <img src="assets/icons/erreur-message.svg" alt="error icon">
        //         <span>${messageContent}</span>
        //     <div>
        // `;
        this.show('app-toast app-toast-error', messageContent, duration, closebutton);
    }

    public async errorCustom(message: string, duration?: number, closebutton?: boolean): Promise<void> {
        this.show('app-toast app-toast-error', this.translateService.instant(message), duration, closebutton);
    }
}
