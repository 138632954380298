import { APP_BASE_HREF, DatePipe, PlatformLocation, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy } from '@angular/router';
import { BandeauEnvModule } from '@components/bandeau-env/bandeau-env.module';
import { ComponentsModule } from '@components/components.module';
import { EmoticCustomScrollbarModule, LoaderModule, LoadingService } from '@emotic/emolib';
import { HttpAddCredentialsInterceptor } from '@interceptors/http-add-credentials.interceptor';
import { HttpConfigInterceptor } from '@interceptors/httpconfig.interceptor';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { createErrorHandler, TraceService } from '@sentry/angular';
import { ConfigService } from '@services/config.service';
import { SecurityService } from '@services/security.service';
import { StorageService } from '@services/storage.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuardService } from '@services/auth-guard.service';
import { PipesModule } from './pipes/pipes.module';
import { AppInjector } from './services/app-injector.service';

registerLocaleData(localeFr);

const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');
const appInitializerFn = (injector: Injector, configService: ConfigService, storage: Storage) => () => {
    AppInjector.setInjector(injector);
    return configService.loadAppConfig().then(() => storage.create());
};

const SERVICES = [
    // Services importés
    Storage,
    // Services du projet
    ConfigService,
    SecurityService,
    StorageService,
    LoadingService,
    AuthGuardService,
];

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    exports: [],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({
            mode: 'md',
        }),
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        EmoticCustomScrollbarModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'fr',
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler,
            },
        }),
        BandeauEnvModule,
        ComponentsModule,
        PipesModule,
        LoaderModule,
    ],
    providers: [
        ...SERVICES,
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        {
            provide: APP_BASE_HREF,
            useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
            deps: [PlatformLocation],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [Injector, ConfigService, Storage],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpAddCredentialsInterceptor,
            multi: true,
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: ['fr'] } },
        {
            provide: ErrorHandler,
            useValue: createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [TraceService],
            multi: true,
        },
        DatePipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
