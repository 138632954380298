import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractComponent } from '@components/abstract/abstract.component';
import { Contact } from '@app/models/contact.model';
import { NavController } from '@ionic/angular';
import { UtilisateurService } from '@app/services/utilisateur.service';
import { Profil } from '@app/models/profil.model';
import { RouteService } from '@app/services/route.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-contact-card',
    templateUrl: './contact-card.component.html',
    styleUrls: ['./contact-card.component.scss'],
})
export class ContactCardComponent extends AbstractComponent {
    @Input()
    public contact: Contact;

    @Input()
    public accompagne: boolean = false;

    @Input()
    public big: boolean = false;

    @Input()
    public highlight: boolean = false;

    @Input()
    public demande: boolean = false;

    @Input()
    public displayActionButton: boolean = true;

    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onClick: EventEmitter<{ contact: Contact; validate: boolean }> = new EventEmitter<{
        contact: Contact;
        validate: boolean;
    }>();

    public TAG: string = '[ContactCardComponent]';

    constructor(
        private navController: NavController,
        private utilisateurService: UtilisateurService,
        private routeService: RouteService,
        private translate: TranslateService,
    ) {
        super();
    }

    public actionButtonClick(): void {
        if (!this.demande && !this.accompagne) {
            // Affichage des détails du contact :
            this.navController.navigateForward('/mes-contacts/' + this.contact.profil?.id + '/details');
        } else if (this.accompagne) {
            // Remplacement du profil courant par celui de l'accompagné :
            this.loadingService.show(this.TAG);

            this.subscriptions.setCurrentProfilById = this.utilisateurService
                .setCurrentProfilById(this.contact.profil.id, this.contact.contactType)
                .subscribe({
                    next: (value: Profil) => {
                        if (!this.routeService.getCurrentUrl().includes('home')) {
                            this.navController.navigateBack('/home');
                        }
                        this.loadingService.hide(this.TAG);
                        this.popoverService.success(
                            this.translate.instant('SUCCESS.ACCOMPAGNE.LOAD', {
                                name: this.utilisateurService.getCurrentNomComplet(),
                            }),
                            true,
                        );
                    },
                    error: (err: any) => this.handleError(err, this.TAG),
                });
        } else {
            this.onClick.emit({ contact: this.contact, validate: true });
        }
    }

    public cancelButtonClick(): void {
        this.onClick.emit({ contact: this.contact, validate: false });
    }
}
