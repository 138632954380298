import { Config } from '@models/config.model';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { setTag } from '@sentry/angular';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    public static config: Config;

    private http: HttpClient;

    constructor(handler: HttpBackend) {
        this.http = new HttpClient(handler);
    }

    public async loadAppConfig(): Promise<Config> {
        return await lastValueFrom(this.http.get('assets/conf/conf.json')).then((data: Config) => {
            ConfigService.config = data;
            setTag('environment', data.env);
            console.groupCollapsed('[ConfigService] Configuration chargée');
            console.log(ConfigService.config);
            console.groupEnd();
            return ConfigService.config;
        });
    }

    public getConfig(): Config {
        return ConfigService.config;
    }
}
