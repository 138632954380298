import { Component, Input } from '@angular/core';
import { AbstractComponent } from '../abstract/abstract.component';

@Component({
    selector: 'app-menu-button',
    templateUrl: './menu-button.component.html',
    styleUrls: ['./menu-button.component.scss'],
})
export class MenuButtonComponent extends AbstractComponent {
    @Input()
    public label: string = '';

    @Input()
    public url?: string;

    @Input()
    public icon?: string;

    @Input()
    public disabled?: boolean;

    constructor() {
        super();
    }
}
