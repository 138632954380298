import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PopoverService } from '@app/services/popover.service';
import { ToastService } from '@app/services/toast.service';
import { BigButtonComponent } from '@components/big-button/big-button.component';
import { BlocInformationComponent } from '@components/bloc-information/bloc-information.component';
import { CardItemComponent } from '@components/card-item/card-item.component';
import { MenuButtonComponent } from '@components/menu-button/menu-button.component';
import { NotificationCardComponent } from '@components/notification-card/notification-card.component';
import { OnboardingFooterComponent } from '@components/onboarding-footer/onboarding-footer.component';
import { OnboardingHeaderComponent } from '@components/onboarding-header/onboarding-header.component';
import { PageTitleComponent } from '@components/page-title/page-title.component';
import { ProfilPhotoComponent } from '@components/profil-photo/profil-photo.component';
import { RoundPictoComponent } from '@components/round-picto/round-picto.component';
import { ContactCardComponent } from '@app/components/contact-card/contact-card.component';
import { ContactListComponent } from '@app/components/contact-list/contact-list.component';
import { RubriqueAccesCardComponent } from '@components/rubrique-acces-card/rubrique-acces-card.component';
import { ImageLoaderModule, LoadingService } from '@emotic/emolib';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PopoverComponent } from './popover/popover.component';
import { ProfilCardComponent } from './profil-card/profil-card.component';
import { PipesModule } from '@app/pipes/pipes.module';

@NgModule({
    imports: [CommonModule, ImageLoaderModule, TranslateModule, RouterModule, IonicModule, PipesModule],
    declarations: [
        ProfilPhotoComponent,
        RoundPictoComponent,
        BigButtonComponent,
        MenuButtonComponent,
        CardItemComponent,
        ProfilCardComponent,
        ContactListComponent,
        ContactCardComponent,
        PopoverComponent,
        OnboardingHeaderComponent,
        OnboardingFooterComponent,
        RubriqueAccesCardComponent,
        PageTitleComponent,
        BlocInformationComponent,
        NotificationCardComponent,
    ],
    exports: [
        ProfilPhotoComponent,
        RoundPictoComponent,
        BigButtonComponent,
        MenuButtonComponent,
        CardItemComponent,
        ProfilCardComponent,
        ContactListComponent,
        ContactCardComponent,
        OnboardingHeaderComponent,
        OnboardingFooterComponent,
        RubriqueAccesCardComponent,
        PageTitleComponent,
        BlocInformationComponent,
        NotificationCardComponent,
    ],
    providers: [LoadingService, ToastService, PopoverService],
})
export class ComponentsModule {
}
