import { Component, Input } from '@angular/core';
import { AbstractComponent } from '@components/abstract/abstract.component';

@Component({
    selector: 'app-round-picto',
    templateUrl: './round-picto.component.html',
    styleUrls: ['./round-picto.component.scss'],
})
export class RoundPictoComponent extends AbstractComponent {
    @Input()
    public big: boolean = false;

    @Input()
    public small: boolean = false;

    @Input()
    public date?: string;

    @Input()
    public icon?: string;

    @Input()
    public danger?: boolean;

    @Input()
    public outline: boolean = false;
}
