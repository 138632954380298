export enum NotificationType {
    CREER_CONTENU = 'CreerContenu',
    MODIFIER_CONTENU = 'ModifierContenu',
    SUPPRIMER_CONTENU = 'SupprimerContenu',
    CONSULTER_CONTENU = 'ConsulterContenu',
    MODIFIER_PROFIL = 'ModifierProfil',
    MODIFIER_ACCES = 'ModifierAcces',
    INITIER_CONTACT = 'InitierContact',
    MODIFIER_CONTACT = 'ModifierContact',
    SUPPRIMER_CONTACT = 'SupprimerContact',
    CHANGER_MOT_DE_PASSE = 'ChangerMotDePasse',
    VALIDER_CONTACT = 'ValiderContact',
    VALIDER_CGU = 'ValiderCGU',
    VALIDER_TYPE_COMPTE = 'ValiderTypeCompte',
    COMPLETE_ONBOARDING = 'CompleteOnboarding',
    ABANDON_ONBOARDING = 'AbandonOnboarding',
    MODIFIER_PERSONNE_DE_CONFIANCE = 'ModifierPersonneDeConfiance',
    SUPPRIMER_PERSONNE_DE_CONFIANCE = 'SupprimerPersonneDeConfiance',
    MODIFIER_TUTEUR = 'ModifierTuteur',
    VALIDER_TUTEUR = 'ValiderTuteur',
    EXPIRER_DOCUMENT = 'ExpirerDocument',
}
