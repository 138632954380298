import { Component, Input, OnInit } from '@angular/core';
import { AbstractComponent } from '@components/abstract/abstract.component';
import { OnboardingStep } from '@models/onboarding-step.model';

@Component({
    selector: 'app-onboarding-header',
    templateUrl: './onboarding-header.component.html',
    styleUrls: ['./onboarding-header.component.scss'],
})
export class OnboardingHeaderComponent extends AbstractComponent {
    @Input()
    public currentStep: OnboardingStep;

    @Input()
    public title: string;

    constructor() {
        super();
    }
}
