import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SecurityService } from '@app/services/security.service';
import { ConfigService } from '@app/services/config.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    public static redirectInProgress = false;

    constructor(private securityService: SecurityService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('[HttpConfigInterceptor] intercept: event--->>>', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                const backendUrl = ConfigService.config.backendUrl;
                const remoteBackend = backendUrl && request.urlWithParams.indexOf(backendUrl) === 0;
                const localBackend = request.urlWithParams.indexOf(location.protocol + '//') !== 0;

                if (remoteBackend || localBackend) {
                    if (error.status === 403 && !this.securityService.isAuthenticated) {
                        if (!HttpConfigInterceptor.redirectInProgress) {
                            console.log('[HttpConfigInterceptor] Redirection vers la page de login ...');
                            HttpConfigInterceptor.redirectInProgress = true;
                            this.securityService.logout().then(() => {
                                setTimeout(() => {
                                    HttpConfigInterceptor.redirectInProgress = false;
                                }, 200);
                            });
                        } else {
                            console.log('[HttpConfigInterceptor] Redirection déjà en cours ...');
                        }
                    }
                }
                return throwError(() => error);
            }),
        );
    }
}
