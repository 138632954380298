import { Directive, Input, OnDestroy } from '@angular/core';
import { AppInjector } from '@app/services/app-injector.service';
import { PopoverService } from '@app/services/popover.service';
import { ToastService } from '@app/services/toast.service';
import { LoadingService } from '@emotic/emolib';
import { Subscription } from 'rxjs';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractComponent implements OnDestroy {
    @Input()
    public isConsultation: boolean = false;

    public TAG: string = '[AbstractComponent]';

    protected loadingService: LoadingService;
    protected toastService: ToastService;
    protected popoverService: PopoverService;

    protected subscriptions: { [key: string]: Subscription } = {};
    protected timeouts: { [key: string]: any } = {};

    constructor() {
        const injector = AppInjector.getInjector();

        this.loadingService = injector.get(LoadingService);
        this.toastService = injector.get(ToastService);
        this.popoverService = injector.get(PopoverService);
    }

    ngOnDestroy() {
        // Subscriptions
        Object.keys(this.subscriptions).forEach(key => {
            const subscription = this.subscriptions[key];
            if (subscription && subscription.unsubscribe) {
                subscription.unsubscribe();
            }
        });
        // Timeouts
        Object.keys(this.timeouts).forEach(key => {
            const timeout = this.timeouts[key];
            if (timeout) {
                clearTimeout(timeout);
            }
        });
    }

    /**
     *
     * @param err
     * @param loaderId : passer '' pour forcer le masquage du loader.
     */
    protected handleError(err: any, loaderId?: string): void {
        console.log(err);

        if (loaderId) {
            this.loadingService.hide(loaderId);
        }
    }
}
