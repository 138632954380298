import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractComponent } from '../abstract/abstract.component';

@Component({
    selector: 'app-big-button',
    templateUrl: './big-button.component.html',
    styleUrls: ['./big-button.component.scss'],
})
export class BigButtonComponent extends AbstractComponent implements OnInit {
    @Output()
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onClick: EventEmitter<Event> = new EventEmitter<Event>();

    @Input()
    public label: string = '';

    @Input()
    public subtitle?: string;

    @Input()
    public light: boolean = false;

    @Input()
    public root: boolean = false;

    @Input()
    public url?: string;

    @Input()
    public icon?: string;

    @Input()
    public disabled?: boolean;

    constructor(private el: ElementRef) {
        super();
    }

    ngOnInit() {
        if (this.light) {
            (this.el.nativeElement as HTMLElement).classList.add('light');
        }
    }

    public clickHandler(e: Event): void {
        this.onClick.emit(e);
    }
}
