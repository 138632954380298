export class ObjectUtils {
    public static groupBy(xs: any[], key: string) {
        return xs.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }

    public static groupByComplexKey(list, keyGetter): Map<string, any[]> {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    public static getEnumKeyByEnumValue<T extends { [index: string]: string }>(myEnum: T,
        enumValue: string): keyof T | null {
        const keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue);
        return keys.length > 0 ? keys[0] : null;
    }
}
