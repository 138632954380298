import { EventEmitter, Injectable } from '@angular/core';
import { Cgu } from '@app/models/cgu.model';
import { LightProfil } from '@app/models/light-profil.model';
import { Profil } from '@app/models/profil.model';
import { Utilisateur } from '@app/models/utilisateur.model';
import { ContactType } from '@models/contact-type.enum';
import { Contact } from '@models/contact.model';
import { UtilisateurType } from '@models/utilisateur-type.enum';
import { Observable, of, Subscriber } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class UtilisateurService {
    public utilisateurUpdate$: EventEmitter<Utilisateur> = new EventEmitter<Utilisateur>();

    public currentProfilUpdate$: EventEmitter<LightProfil> = new EventEmitter<LightProfil>();

    public rubriquesAccessUpdate$: EventEmitter<Record<string, boolean>> = new EventEmitter<Record<string, boolean>>();

    public TAG: string = '[UtilisateurService]';

    private utilisateur: Utilisateur;

    private currentProfil: Profil;
    private currentContactType: ContactType;

    private rubriquesAccess: Record<string, boolean>;

    constructor(private apiService: ApiService) {}

    public isConsultation(): boolean {
        return this.getUtilisateurProfil()?.id !== this.getCurrentProfil()?.id;
    }

    public isTuteur(): boolean {
        return this.isConsultation && this.getCurrentContactType() === ContactType.TUTEUR;
    }

    public isPersonneDeConfiance(): boolean {
        return this.isConsultation && this.getCurrentContactType() === ContactType.PERSONNE_DE_CONFIANCE;
    }

    public isBeneficiaire(): boolean {
        return this.getCurrentUtilisateurType() === UtilisateurType.BENEFICIAIRE;
    }

    public resetCurrentProfil(): void {
        this.setCurrentProfil(this.utilisateur?.profil, ContactType.AUTRE);
    }

    public setCurrentProfil(value: Profil, contactType?: ContactType): void {
        console.log(this.TAG, 'setCurrentProfil()', value, contactType);

        if (this.currentProfil === value) {
            return;
        }

        this.currentProfil = value;

        if (contactType) {
            this.currentContactType = contactType;
        }

        this.currentProfilUpdate$.emit(this.currentProfil);
    }

    public setCurrentProfilById(id: string, contactType?: ContactType): Observable<Profil> {
        console.log(this.TAG, 'setCurrentProfilById()', id, contactType);

        if (this.currentProfil?.id === id) {
            return of(this.currentProfil);
        }

        return new Observable<Profil>((observer: Subscriber<Profil>) => {
            this.getProfil(id).subscribe({
                next: (value: Profil) => {
                    this.setCurrentProfil(value, contactType);

                    observer.next(value);
                },
                error: (err: any) => observer.error(err),
            });
        });
    }

    public getCurrentContactType(): ContactType {
        return this.currentContactType || ContactType.AUTRE;
    }

    public getCurrentProfil(): Profil {
        return this.currentProfil || this.getUtilisateurProfil();
    }

    public getCurrentId(): string {
        return this.currentProfil?.id || this.getUtilisateurId();
    }

    public getCurrentNomComplet(): string {
        if (!this.currentProfil) {
            return this.getNomComplet();
        }
        return this.currentProfil.prenom + ' ' + this.currentProfil.nomUsage;
    }

    public getCurrentPhotoUrl(): string {
        return this.currentProfil ? this.currentProfil.photoUrl : this.getPhotoUrl();
    }

    public getCurrentUtilisateurType(): UtilisateurType {
        return this.currentProfil?.utilisateurType || this.getUtilisateurType();
    }

    public setUtilisateur(value: Utilisateur): void {
        console.log(this.TAG, 'setUtilisateur()', value);

        if (this.utilisateur === value) {
            return;
        }

        this.utilisateur = value;

        this.utilisateurUpdate$.emit(this.utilisateur);
    }

    public getUtilisateur(): Utilisateur {
        return this.utilisateur;
    }

    public getUtilisateurProfil(): Profil {
        return this.utilisateur?.profil;
    }

    public updateUtilisateurProfil(value: Profil): void {
        this.utilisateur.profil = value;

        this.utilisateurUpdate$.emit(this.utilisateur);
    }

    public getUtilisateurId(): string {
        return this.utilisateur?.id;
    }

    public getNomComplet(): string {
        if (!this.utilisateur?.profil) {
            return '';
        }
        return (
            this.utilisateur.profil.prenom +
            ' ' +
            (this.utilisateur.profil.nomUsage || this.utilisateur.profil.nomNaissance)
        );
    }

    public getPhotoUrl(): string {
        return this.utilisateur?.profil.photoUrl;
    }

    public getUtilisateurType(): UtilisateurType | undefined {
        return this.utilisateur?.utilisateurType;
    }

    public getProfil(id: string): Observable<Profil> {
        return this.apiService.getProfil(id);
    }

    public getTuteur(): Contact {
        return this.utilisateur?.contacts?.find(({ contactType }) => contactType === ContactType.TUTEUR);
    }

    public updateProfil(profil: Profil): Observable<Profil> {
        return this.apiService.updateProfil(this.utilisateur.id, profil);
    }

    public acceptCgu(cgu: Cgu): Observable<any> {
        return this.apiService.acceptCgu(cgu);
    }

    public validateUtilisateurType(data: UtilisateurType): Observable<any> {
        return this.apiService.validateUtilisateurType({ utilisateurType: data });
    }

    public validateTuteur(data: Contact): Observable<any> {
        return this.apiService.validateTuteur(this.utilisateur.id, data);
    }

    public updateRubriquesAccess(value: Record<string, boolean>): void {
        this.rubriquesAccess = value;

        this.rubriquesAccessUpdate$.emit(this.rubriquesAccess);
    }

    public isActivated(activationKey: string): Observable<{ isActivated: boolean }> {
        return this.apiService.isActivated(activationKey);
    }
}
