import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UtilisateurType } from '@app/models/utilisateur-type.enum';
import { UtilisateurService } from './utilisateur.service';

@Injectable({
    providedIn: 'root',
})
export class PrestataireGuardService implements CanActivate {
    constructor(public utilisateurService: UtilisateurService, public router: Router) {}

    canActivate(): boolean {
        if (this.utilisateurService.getCurrentUtilisateurType() === UtilisateurType.BENEFICIAIRE) {
            this.router.navigate(['home']);
            return false;
        }
        return true;
    }
}
