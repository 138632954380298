import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConfigService } from './config.service';
import { Storage } from '@ionic/storage-angular';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    readonly prefix: string;

    constructor(private storage: Storage) {
        this.prefix = `${environment.client}_${environment.projet}_${ConfigService.config.env}_`;
    }

    /**
     * Retourne l'item dans le storage ayant pour clé la clé donnée en paramètre.
     *
     * @param key Le clé correspondant à l'item souhaité.
     * @returns Returns a promise with the value of the given key
     */
    get(key: string): Promise<any> {
        return this.storage.get(this.getKey(key));
    }

    /**
     * Sauvegarde l'item dans le storage avec la clé donnée en paramètre.
     *
     * @param key Le clé correspondant à la valeur à sauvegarder.
     * @param value La valeur à sauvegarder.
     * @returns Returns a promise that resolves when the key and value are set
     */
    set(key: string, value: any): Promise<any> {
        return this.storage.set(this.getKey(key), value);
    }

    /**
     * Supprime un item du storage
     *
     * @param key La clé métier de l'item à supprimer.
     * @returns Returns a promise that resolves when the value is removed
     */
    remove(key: string): Promise<any> {
        return this.storage.remove(this.getKey(key));
    }

    /**
     * Retourne la clé de storage correspondant à l'application courante. On rajoute un préfixe pour éviter les conflits entre applications.
     *
     * @param key La clé que l'on veut transformer.
     * @returns La clé transformée.
     */
    private getKey(key: string): string {
        return this.prefix + '' + key;
    }
}
