import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { catchError, from, map, Observable, of, switchMap, throwError } from 'rxjs';
import { ConfigService } from './config.service';
import { UtilisateurService } from '@services/utilisateur.service';

import { StorageService } from '@services/storage.service';
import { ApiService } from './api.service';
import { Utilisateur } from '@app/models/utilisateur.model';
const TOKEN_JWT = 'JWT';
@Injectable({
    providedIn: 'root',
})
export class SecurityService {
    public TAG: string = '[SecurityService]';

    public isAuthenticatedUpdate$: EventEmitter<boolean> = new EventEmitter<boolean>();
    public isAuthenticated: boolean = false;
    public tokenJwt: string; // TODO: utiliser http-secure et stocker le token JWT dans les cookies du back (front ?)

    constructor(
        public http: HttpClient,
        private utilisateurService: UtilisateurService,
        private apiService: ApiService,
        private storage: StorageService,
    ) {}

    public loadPreviousSession(): Observable<Utilisateur> {
        return from(this.storage.get(TOKEN_JWT)).pipe(
            switchMap(tokenJwt => {
                if (tokenJwt) {
                    this.tokenJwt = tokenJwt;
                    return this.me();
                }

                return of(null);
            }),
        );
    }

    public me(): Observable<Utilisateur> {
        return this.apiService.getUtilisateur().pipe(
            map((utilisateur: Utilisateur) => {
                this.utilisateurService.setUtilisateur(utilisateur);
                this.updateIsAuthenticated(!!this.utilisateurService.getUtilisateur());
                return this.utilisateurService.getUtilisateur();
            }),
        );
        // this.http
        //     .get<{ id: string }>(`${ConfigService.config.backendUrl}api/mobile/account`)
        //     // .pipe(switchMap(account => this.utilisateurService.getProfil(account.id)))
        //     .pipe(switchMap(account => this.utilisateurService.getUtilisateur(true)))
        //     .pipe(
        //         map((utilisateur: Utilisateur) => {
        //             this.utilisateurService.setUtilisateur(utilisateur);
        //             this.updateIsAuthenticated(!!this.utilisateurService.getUtilisateur());
        //             return this.utilisateurService.getUtilisateur();
        //         }),
        //     )
    }

    public login(mail: string, motDePasse: string, seSouvenir: boolean): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        return this.http
            .post(
                `${ConfigService.config.backendUrl}api/mobile/authenticate`,
                { username: mail, password: motDePasse, rememberMe: seSouvenir },
                { headers },
            )
            .pipe(catchError(this.handleError.bind(this)))
            .pipe(
                switchMap((tokenDTO: { id_token: string }) => {
                    // console.log(this.TAG, 'login() tokenDTO:', tokenDTO);
                    this.setTokenJWT(tokenDTO.id_token);
                    return this.me();
                }),
            );
    }

    public async logout(): Promise<void> {
        this.updateIsAuthenticated(false);
        this.tokenJwt = undefined;
        this.storage.set(TOKEN_JWT, undefined);
        this.utilisateurService.setUtilisateur(undefined);
        this.utilisateurService.resetCurrentProfil();
        return Promise.resolve();

        // FIXME: api/users/logout n'existe pas.
        // return await lastValueFrom(this.http.get<void>(`${ConfigService.config.backendUrl}api/users/logout`)).then(
        //     data => {
        //         this.updateIsAuthenticated(false);
        //         this.tokenJwt = undefined;
        //         this.storage.set(TOKEN_JWT, undefined);
        //         this.utilisateurService.setUtilisateur(undefined);
        //     },
        // );
    }

    public setTokenJWT(value: string): void {
        this.tokenJwt = value;
        if (!this.tokenJwt.startsWith('Bearer')) {
            this.tokenJwt = 'Bearer ' + this.tokenJwt;
        }
        this.storage.set(TOKEN_JWT, this.tokenJwt);
    }

    public updateIsAuthenticated(value: boolean): void {
        this.isAuthenticated = value;
        this.isAuthenticatedUpdate$.emit(this.isAuthenticated);
    }

    public passwordReset(activationKey: string, motDePasse: string): Observable<any> {
        return this.apiService.passwordReset(activationKey, motDePasse);
    }

    public passwordForgot(mail: string): Observable<any> {
        return this.apiService.passwordForgot(mail);
    }

    public passwordChange(currentPassword: string, newPassword: string): Observable<any> {
        return this.apiService.passwordChange(currentPassword, newPassword);
    }

    // TODO : devrait etre dans une abstract classe commune a tous les services
    private handleError(error: HttpErrorResponse): Observable<never> {
        if (!!error) {
            if (!!error.error && !!error.error.message) {
                return throwError(() => new Error(error.error.message));
            } else if (error.message) {
                return throwError(() => new Error(error.message));
            } else if (typeof error === 'string') {
                return throwError(() => new Error(error));
            }
        }
        return throwError(() => new Error('Une erreur est survenue.'));
    }
}
