import { Component, Input, OnInit } from '@angular/core';
import { UtilisateurService } from '@app/services/utilisateur.service';
import { UtilisateurType } from '@models/utilisateur-type.enum';
import { AbstractComponent } from '../abstract/abstract.component';

@Component({
    selector: 'app-profil-card',
    templateUrl: './profil-card.component.html',
    styleUrls: ['./profil-card.component.scss'],
})
export class ProfilCardComponent extends AbstractComponent implements OnInit {
    @Input()
    public username?: string;

    @Input()
    public utilisateurType?: UtilisateurType;

    @Input()
    public photoUrl?: string;

    constructor(private utilisateurService: UtilisateurService) {
        super();
    }

    ngOnInit(): void {
        this.subscriptions.utilisateurUpdate = this.utilisateurService.utilisateurUpdate$.subscribe({
            next: () => this.updateProfil(),
            error: (err: any) => this.handleError(err),
        });

        this.subscriptions.currentProfilUpdate = this.utilisateurService.currentProfilUpdate$.subscribe({
            next: () => this.updateProfil(),
            error: (err: any) => this.handleError(err),
        });

        this.updateProfil();
    }

    private updateProfil(): void {
        this.username = this.utilisateurService.getCurrentNomComplet();
        this.photoUrl = this.utilisateurService.getCurrentPhotoUrl();
        this.utilisateurType = this.utilisateurService.getCurrentUtilisateurType();
    }
}
