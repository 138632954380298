import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@app/services/config.service';

@Component({
    selector: 'app-bandeau-env',
    styleUrls: ['./bandeau-env.component.scss'],
    templateUrl: './bandeau-env.component.html',
})
export class BandeauEnvComponent implements OnInit {
    public env: string;
    public isProd: boolean;

    constructor(private configSrv: ConfigService) {}

    ngOnInit() {
        this.env = this.configSrv.getConfig().env;
        this.isProd = this.env.includes('prod');
    }
}
